<template>
  <v-row justify="center" align="center">
    <v-col>
      <div class="text-center">
        <vuetify-logo />
      </div>
      <div class="checkout-section section">
        <checkout />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import VuetifyLogo from "../components/VuetifyLogo.vue";
import checkout from "../components/checkout.vue";

export default {
  components: {
    // Logo,
    VuetifyLogo,
    checkout,
  },
  data() {
    return {
      // settings: [],
    };
  },
};
</script>
<style>
/* .section {
  padding: 20px 20px 0px 20px;
} */
</style>
