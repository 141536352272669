<template>
  <div class="outRyad-component">
    <p class="head-title">شحن عبر شركة سمسا بـ 35 ريال حتى 15 كيلو.</p>
    <form class="form-full">
      <label class="form-label">الاسم</label>
      <v-text-field
        v-model="auth.name"
        label="الاسم"
        solo
        required
        @input="$v.auth.name.$touch()"
        @blur="$v.auth.name.$touch()"
        @validate="validate($event)"
      ></v-text-field>
      <label class="form-label">رقم الجوال</label>
      <div class="tel-form">
        <vue-tel-input
          v-model="auth.phone"
          type="tel"
          :error-messages="phoneErrors"
          v-bind="bindProps"
          required
          @input="$v.auth.phone.$touch()"
          @blur="$v.auth.phone.$touch()"
          @validate="validate($event)"
          ref="mobileInput"
        />
      </div>
      <label class="form-label">المدينة</label>
      <v-autocomplete
        v-model="shoppingTo.city"
        :items="cities"
        item-value="id"
        item-text="name"
        :error-messages="cityErrors"
        label="المدينة"
        solo
        @input="$v.shoppingTo.city.$touch()"
        @blur="$v.shoppingTo.city.$touch()"
      ></v-autocomplete>
      <label class="form-label">الحي , الشارع , رقم البيت</label>
      <v-text-field
        v-model="shoppingTo.location"
        :error-messages="locationErrors"
        class="text-center"
        label=" الحي , الشارع , رقم البيت"
        required
        solo
        @input="$v.shoppingTo.location.$touch()"
        @blur="$v.shoppingTo.location.$touch()"
      ></v-text-field>
      <label class="form-label">كود الخصم</label>
      <v-text-field
        v-model="discount"
        label="كود الخصم"
        solo
        @keyup="checkCouponCode"
        class="discount"
      ></v-text-field>
      <p :style="'margin-bottom:20px;color:' + discountMessageColor">
        {{ discountMessage }}
      </p>
      <!-- <v-select
        :items="payMethods"
        v-model="shoppingTo.paymentMethod"
        :error-messages="paymentErrors"
        required
        solo
        label="طريقة الدفع"
        item-value="key"
        item-text="value"
        @input="$v.shoppingTo.paymentMethod.$touch()"
        @blur="$v.shoppingTo.paymentMethod.$touch()"
      ></v-select> -->
      <label class="form-label">ملاحظات</label>
      <v-textarea v-model="shoppingTo.notes" label="ملاحظات" solo></v-textarea>
    </form>
    <!-- <invoice /> -->
    <div class="invoice-component">
      <div class="paper-head">
        <img src="../../static/paper-head.png" alt="head" />
      </div>
      <div class="paper-item">
        <div class="order-details">
          <div style="text-align: center">
            <vuetify-logo />
          </div>
          <span
            style="
              font-size: 10px;
              font-weight: initial;
              color: #5a5a5a !important;
            "
            >الرقم الضريبي : {{ settings.tax_number }}</span
          >
          <div class="table">
            <v-container id="regular-tables" fluid tag="section">
              <v-card>
                <v-data-table
                  :headers="headers"
                  :items="products"
                  item-key="id"
                  :mobile-breakpoint="0"
                >
                  <template v-slot:[`item.name`]="{ item }">
                    <div class="name-green">
                      {{ item.product_name | strippedContent }}
                    </div>
                  </template>
                  <template v-slot:[`item.quantity`]="{ item }">
                    {{ item.quantity }}
                  </template>
                  <template v-slot:[`item.price`]="{ item }">
                    {{ item.price }} ريال
                  </template>
                </v-data-table>
              </v-card>
            </v-container>
          </div>
          <hr />

          <div class="footer-reset">
            <p class="total-count">
              <span>الإجمالي </span>
              <span class="total-money left-price">{{ totalAmount }} ريال</span>
            </p>
            <p class="total-count" v-if="discountAmount > 0">
              <span>الخصم </span>
              <span class="total-money left-price"
                >{{ discountAmount }} ريال</span
              >
            </p>
            <p class="total-count" style="">
              <span> التوصيل </span>
              <span class="total-money-delivary left-price"
                >{{ order }} ريال</span
              >
            </p>

            <p class="total-count" style="margin-top: 40px">
              <span>المجموع الكلي </span>
              <span class="total-money-all left-price"
                >{{ totalSummation }}
                <span style="font-size: 14px; color: #5a5a5a !important"
                  >ريال</span
                ></span
              >
            </p>
            <br />
            <p class="total-count">
              <span style="color: #5a5a5a; font-size: 9px; font-weight: normal"
                >ضريبة القيمة المضافة ({{ settings.vat }}%)
              </span>
              <span
                class="total-money-tax left-price"
                style="color: #5a5a5a; font-size: 11px; font-weight: normal"
                >{{ VAT }} ريال</span
              >
            </p>
            <!-- <span style="font-size: 10px; font-weight: initial"
              >الرقم الضريبي : 302215788100003</span
            ><br /> -->
          </div>

          <v-btn
            @click="setPass()"
            :disabled="parseInt(totalAmount) == 0"
            class="btn complete-btn"
            >اكمال الطلب</v-btn
          >
        </div>
      </div>
    </div>
    <!-- <v-dialog v-model="dialog1">
      <v-card>
        <v-btn icon dark @click="dialog1 = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title class="buttons d-flex"> </v-card-title>

        <v-card-text>
          <span>؟هل انت متأكد من هذا الرقم</span>
          <v-text-field
            v-model="auth.phone"
            type="tel"
            solo
            disabled
          ></v-text-field>
          <span>الاسم</span>
          <v-text-field
            v-model="shoppingTo.name"
            :error-messages="passwordErrors"
            label="أدخل اسمك"
            type="text"
            solo
            required
            @input="$v.shoppingTo.name.$touch()"
            @blur="$v.shoppingTo.name.$touch()"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <button class="btn btn-success" @click="signup()">حفظ</button>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <v-dialog v-model="dialog1">
      <v-card class="p-5">
        <v-card-title> </v-card-title>
        <v-card-text class="m-auto" dir="rtl">
          هل تريد تأكيد الطلب برقم الجوال
          <span dir="ltr">{{ auth.phone }}</span>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="changeMobile()" large>تغيير</v-btn>
          <v-btn @click="signup()" color="success" large>موافق</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog3">
      <v-card class="p-5">
        <v-card-title> </v-card-title>
        <v-card-text class="m-auto" dir="rtl">
          رقم الجوال غير صحيح
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialog3 = false" color="success" large>موافق</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" color="success" top right>
      {{ massege }}
    </v-snackbar>
    <v-snackbar v-model="snackbar1" color="red" top right>
      {{ massege }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
// import invoice from '../invoice.vue'
import VuetifyLogo from "../../components/VuetifyLogo.vue";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { API_URL } from "../../config";
import { ServiceFactory } from "../../services/ServiceFactory";
const cartService = ServiceFactory.get("cart");
const service = ServiceFactory.get("register");
const citiesService = ServiceFactory.get("cities");

export default {
  mixins: [validationMixin],
  components: {
    VuetifyLogo,
  },
  filters: {
    strippedContent: function (string) {
      return string.replace(/<\/?[^>]+>/gi, " ");
    },
  },
  props: ["settings"],

  validations: {
    auth: {
      phone: {
        required,
        tel: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      },
      name: { required },
      password: { required },
    },
    shoppingTo: {
      name: { required },
      city: { required },
      location: { required },
      paymentMethod: { required },
    },
    discount: { minLength: minLength(4) },
  },

  data: () => ({
    shoppingTo: {
      name: "",
      location: "",
      city: "",
      notes: "",
      paymentMethod: null,
    },
    auth: {
      name: "",
      phone: "",
      user_type: "user",
    },
    payMethods: [
      { key: "credit", value: "فيزا" },
      { key: "cash", value: "كاش" },
    ],
    order_type: "outRyad",
    sities: [],
    cities: [],
    discount: "",
    VAT: "",
    Kilo: null,
    order: 0,
    totalSummation: "",
    dialog: false,
    dialog1: false,
    dialog3: false,
    snackbar: false,
    snackbar1: false,
    phoneChanged: false,
    massege: "",
    categories: [],
    formCards: [],
    totalAmount: null,
    discountAmount: 0,
    discountMessage: "",
    discountMessageColor: "red",
    phoneValid: false,
    bindProps: {
      mode: "international",
      disabledFetchingCountry: false,
      disabledFormatting: false,
      defaultCountry: "SA",
      placeholder: "أدخل رقم الهاتف",
      required: true,
      enabledCountryCode: false,
      type: "tel",
      formattedNumber: true,
      autocomplete: "on",
      autofocus: true,
      onlyCountries: ["SA", "UAE", "KW", "OM", "BH", "EG"],
      name: "telephone",
      validCharactersOnly: true,
      dropdownOptions: {
        disabledDialCode: true,
        showFlags: true,
        showDialCodeInList: true,
        showDialCodeInSelection: true,
      },
      inputOptions: {
        // showDialCode: true,
        type: "tel",
        placeholder: "رقم الجوال",
      },
    },

    headers: [
      { text: "الاسم", value: "name", sortable: false },
      { text: "الكمية", value: "quantity", sortable: false },
      { text: "سعر الوحدة", value: "price", sortable: false },
    ],
  }),
  // components: {
  //    invoice
  // },

  computed: {
    ...mapGetters(["total"]),
    products() {
      return this.$store.state.products;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.auth.phone.$dirty) return errors;
      !this.$v.auth.phone.required && errors.push("يجب ادخال رقم الهاتف");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.auth.password.$dirty) return errors;
      !this.$v.auth.password.required && errors.push("يجب ادخال كلمة مرور");
      return errors;
    },
    paymentErrors() {
      const errors = [];
      if (!this.$v.shoppingTo.paymentMethod.$dirty) return errors;
      !this.$v.shoppingTo.paymentMethod.required &&
        errors.push("يجب اختيار طريقة دفع");
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.shoppingTo.city.$dirty) return errors;
      !this.$v.shoppingTo.city.required && errors.push("يجب اختيار مدينة");
      return errors;
    },

    locationErrors() {
      const errors = [];
      if (!this.$v.shoppingTo.location.$dirty) return errors;
      !this.$v.shoppingTo.location.required && errors.push("يجب تحديد العنوان");
      return errors;
    },
    discountErrors() {
      const errors = [];
      if (!this.$v.discount.$dirty) return errors;
      !this.$v.discount.minLength && errors.push("يجب ادخال كود خصم صالح");
      return errors;
    },
  },
  created() {
    this.fetchAllCities();
    this.totalMoneyInInvoice();
    this.vat();
    this.orderCost();
    this.totalSummations();
    if (localStorage.getItem("mobile") != undefined) {
      this.auth.phone = localStorage.getItem("mobile");
    }
    // eslint-disable-next-line no-undef
    $(document).ready(function () {
      // eslint-disable-next-line no-undef
      $(".discount .v-text-field__details").remove();
    });
  },

  methods: {
    validate(event) {
      console.log(event);
      this.phoneValid = event.valid;
    },
    changeMobile() {
      this.dialog1 = false;
      this.phoneChanged = true;
      window.scrollTo(0, this.$refs.mobileInput.offsetTop);
      setTimeout(() => {
        document.querySelector(".vti__input").focus();
      }, 1000);
    },
    checkCouponCode() {
      this.discountMessage = "";
      const resource = `${API_URL}/checkout/checkCoupon?coupon_code=`;
      this.$http
        .post(
          resource,
          {
            products: this.products,
            subTotal: this.totalAmount,
            coupon_code: this.discount,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            if (response.data.data.valid) {
              this.discountMessageColor = "green";
              this.discountMessage =
                "كوبون صالح وتم تطبيق خصم بقيمة " +
                response.data.data.amount +
                " ريال سعودي";
              this.discountAmount = response.data.data.amount;
              // this.totalAmount = this.totalAmount - response.data.data.amount;
            } else {
              this.discountAmount = 0;
              this.discountMessageColor = "red";
              this.discountMessage = response.data.data.message;
            }
            this.vat();
            this.totalSummations();
          }
          this.dataLoading = false;
        });
    },
    async fetchAllCities() {
      this.dataLoading = true;
      const data = await citiesService.getAllCities();
      this.cities = data.data;
      this.dataLoading = false;
    },

    totalMoneyInInvoice() {
      this.totalAmount = this.products.map(
        (item) => (item.total = item.quantity * item.price)
      );
      this.totalAmount = this.products.reduce((n, { total }) => n + total, 0);
    },
    vat() {
      this.VAT =
        this.discountAmount > 0
          ? ((this.totalAmount - this.discountAmount) *
              parseInt(this.settings.vat)) /
            100
          : (this.totalAmount * parseInt(this.settings.vat)) / 100;
    },
    orderCost() {
      this.Kilo = this.products.map(
        (item) => (item.totalKG = item.Kg * item.quantity)
      );
      const totalkg = this.products.reduce((n, { totalKG }) => n + totalKG, 0);
      if (totalkg > 15) {
        // this.order = totalkg;
        this.order = 35;
      } else {
        this.order = 35;
      }
    },
    totalSummations() {
      this.totalSummation =
        this.discountAmount > 0
          ? Math.ceil(
              this.totalAmount - this.discountAmount + this.VAT + this.order
            )
          : Math.ceil(this.totalAmount + this.VAT + this.order);
    },
    openMap() {
      this.dialog = true;
    },
    submit() {
      this.$v.$touch();
    },
    setPass() {
      if (this.phoneValid) {
        if (
          this.auth.phone &&
          this.shoppingTo.location &&
          this.shoppingTo.city &&
          this.auth.name
        ) {
          this.dialog1 = true;
        } else {
          this.massege = " يجب ملئ الحقول المطلوبة";
          this.snackbar1 = true;
        }
      } else {
        this.dialog3 = true;
      }
    },
    async signup() {
      this.dataLoading = true;
      const auth = {
        name: this.auth.name,
        mobile: this.auth.phone.split(" ").join(""),
      };
      this.$store.commit("addPhone", {
        phone: this.auth.phone.split(" ").join(""),
      });
      this.$store.commit("addShoppingData", {
        city: this.shoppingTo.city,
        location: this.shoppingTo.location,
        paymentMethod: this.shoppingTo.paymentMethod,
        notes: this.shoppingTo.notes,
        discount: this.discount,
        order_type: this.order_type,
        totalPrice: this.totalSummation,
      });
      if (localStorage.token && !this.phoneChanged) {
        const allData = {
          products: this.products,
          shipping_to: this.shoppingTo,
          order_type: this.order_type,
          payment_method: this.paymentMethod,
          coupon_code: this.discount,
        };

        const completeCart = await cartService.checkOut(allData);
        // console.log(completeCart);
        if (completeCart.status.error === false) {
          // this.$router.push(
          //   "/paymentMethod?orderId=" + completeCart.data.order_id
          // );
          window.location =
            "/paymentMethod?orderId=" + completeCart.data.order_id;
        } else if (completeCart.status === 401) {
          // console.log("here");
          const signup = await service.signup(auth);
          if (signup.status.error == false) {
            this.$router.push("/confirm");
          } else {
            this.massege = signup.status.message;
            this.snackbar = true;
          }
          this.dialog1 = false;

          this.dataLoading = false;
        } else {
          this.message = completeCart.status.message;
          this.snackbar1 = true;
        }
      } else {
        const signup = await service.signup(auth);
        if (signup.status.error == false) {
          this.$router.push("/confirm");
        } else {
          this.massege = signup.status.message;
          this.snackbar = true;
        }
        this.dialog1 = false;

        this.dataLoading = false;
      }
    },
  },
};
</script>
<style>
.outRyad-component .head-title {
  text-align: center;
  font-size: 14px;
}
.outRyad-component .v-text-field__details {
  display: none !important;
}
.invoice-component {
  width: 100%;
}
.invoice-component .paper-head {
  margin-bottom: 10px;
  position: relative;
}
.invoice-component .paper-head img {
  width: 100%;
}
.invoice-component .paper-head::after {
  content: "";
  position: absolute;
  background-image: url(../../static/paper.png);
  bottom: -17px;
  right: 7.3%;
  left: 7.3%;
  height: 23px;
  background-size: 21px;
  background-repeat: repeat-x;
}
.theme--light.v-data-table .v-data-footer {
  display: none;
}
.v-application--is-ltr
  .v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  text-align: right;
}
.name-green {
  color: #4c9e53;
}
.invoice-component .paper-item {
  background-color: #fff;
  margin: 0 7.3%;
  padding: 5px 10px 15px;
  position: relative;
}
.invoice-component .paper-item::after {
  content: "";
  position: absolute;
  background-image: url(../../static/paper.png);
  top: -22px;
  right: 0;
  left: 0;
  height: 23px;
  background-size: 21px;
  background-repeat: repeat-x;
  transform: rotate(180deg);
}
.invoice-component .complete-btn {
  display: block;
  text-align: center;
  background-color: #4c9e53;
  color: #fff;
  border-radius: 37px;
  padding: 9px;
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  border: none;
  font-family: "frutiger lt arabic 55 roman" !important;
}
.outRyad-component .tel-form {
  direction: ltr !important;
  text-align: left;
  max-width: 600px;
  margin-bottom: 10px !important;
}
.tel-form input {
  border-radius: 7px;
  font-size: 14px;
  max-width: 100%;
  padding: 15px;
  border: none;
  outline: none;
  background-color: #fff;
}
.footer-reset {
  margin-bottom: 11px;
}
.footer-reset .total-count {
  margin: 5px 0;
}
.left-price {
  float: left;
  position: relative;
  left: 56px;
}
.total-money-all {
  font-weight: 600;
  font-size: 24px;
  color: #5fad66;
}
</style>
